import Img from "gatsby-image"
import { IPartner } from './contentfu.interface';
import React from 'react';
import styled from '@emotion/styled';

import { Col, Row, Typography } from 'antd';
import { H1, H2, H2Bold } from './typography';

const Container = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  height: fit-content;
  padding: 2rem; 
`;

const StyledCol = styled(Col)`
display: flex;
padding: 3.5rem;
@media (max-width: 700px) {
  padding: 0.75rem;
    }
`



const PartnerContainer = styled(Img)`
  width: 100%;
  align-self: center;
`;

type Props = {
  partners: IPartner;
};
const Partners: React.FC<Props> = ({ partners }) => {
  return (
    <Container>
      <H2Bold style={{marginBottom: "2rem", textAlign: 'center'}}>
        {partners.title}
      </H2Bold>
      <Row justify={'center'} style={{width: '100%'}} > 
      {partners &&
        partners.partner.map((x, key) => {
          return (
            <StyledCol xs={12} sm={12} md={8} lg={6} key={key}>
            <PartnerContainer
                key={key}
                fluid={x.img.fluid}
                alt={x.img.title}
                title={x.companyName}
                />
            </StyledCol>
          );
        })}
        </Row>
    </Container>
  );
};
export default Partners;
