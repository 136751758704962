import { IReview } from '../shared/contentfu.interface';
import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

import { Carousel, Col, Row, Typography } from 'antd';
import { H3, TextRegular, TextXsmall } from '../shared/typography';

const ReviewText = styled.div`
  flex: 2;
  height: 100%;
  padding: 1rem;
  text-align: center;
  width: 60%;
  margin: auto;
    @media (max-width: 700px) {
      width: 100%;
    }
`;

const ReviewDetails = styled.div`
  flex: 1;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;

  p {
    text-align: center;      
    font-size: 16px;
    margin-bottom: 0;

  }
`;

const StyledHeadline = styled.h1`
  font-weight: 600;
  font-family: sans-serif;
  font-size: 80px;
  color: ${theme.colors.brandLight.dark};
  text-align: center;
  margin: 0;
`;

const StyledCarousel = styled(Carousel)`
  color: black;
  margin: 0 4rem 0 4rem;
  @media (max-width: 1100px) {
    margin: 0;
  }

  .ant-carousel .slick-dots li {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    opacity: 1;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    opacity: 1;
  }

  .slick-dots li button {
    background:${theme.colors.brandLight.dark};
    width: 6px;
    height: 6px;
    border-radius: 100%;
    opacity: 1;
  }

   .slick-dots li.slick-active button {
    background: ${theme.colors.bgLight.white};
    border: 2px solid${theme.colors.brandLight.dark};
    width: 8px;
    height: 8px;
    border-radius: 100%;
    opacity: 1;

  }
`;

type Props = {
  title: string;
  reviews: IReview[];
};

const ReviewCarousel: React.FC<Props> = ({ reviews, title }: Props) => {
  return (
    <Row gutter={[0, 60]}>
      <Col span={24}>
        <StyledHeadline>
          {title}
        </StyledHeadline>
        <StyledCarousel autoplay swipe>
          {reviews &&
            reviews.map((review, key) => (
              <Row key={key}>
                <Col>
                  <ReviewText>
                    <TextXsmall style={{fontStyle: 'italic'}}>{review.text.text}</TextXsmall>
                  </ReviewText>
                  <ReviewDetails>
                    <TextRegular>{review.name}</TextRegular>
                    <TextRegular style={{fontStyle: 'italic'}}>— {review.company}</TextRegular>
                  </ReviewDetails>
                </Col>
              </Row>
            ))}
        </StyledCarousel>
      </Col>
    </Row>
  );
};
export default ReviewCarousel;
